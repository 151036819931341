<template>
  <div>
    <FormWrapper class="wrapper">
      <iframe id="sow"></iframe>
      <template #footer>
        <CustomButton
          color="white"
          size="large"
          :iconVisible="false"
          @onClick="$router.go(-1)"
          label="Back"
          testId="cancelSow"
        />
        <CustomButton
          color="blue"
          size="large"
          :disabled="saving || sending"
          @onClick="savePdf"
          :label="saving ? 'Saving...' : 'Save'"
          testId="saveSow"
        />
        <CustomButton
          :disabled="saving || sending"
          :label="sending ? 'Sending...' : 'Save & send'"
          color="blue"
          size="large"
          @onClick="saveAndSend"
          testId="saveAndSendSow"
        />
      </template>
    </FormWrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import authApi from '@/api/Authenticated'
import FormWrapper from '@/components/molecules/Form.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    FormWrapper,
    CustomButton
  },
  async setup() {
    const route = useRoute()
    const router = useRouter()
    const sending = ref(false)
    const saving = ref(false)
    let pdfBlob = new Blob()
    const projectId = +route.params.projectId
    const createPdf = () => {
      const blob = new Blob([pdfBlob], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const iframe = document.getElementById('sow')
      if (iframe) {
        iframe.setAttribute('src', `${url}#zoom=FitH`)
      }
    }
    const getPdf = async () => {
      pdfBlob = await authApi.sowPdf(+route.params.projectId, true, false)
      createPdf()
    }
    getPdf()

    const savePdf = async () => {
      saving.value = true
      const res = await authApi.sowPdf(+route.params.projectId, false, false)
      if (res) {
        saving.value = false
        router.push({ name: 'ProjectDocuments', params: { id: projectId } })
      }
    }
    const downloadFile = async () => {
      const blob = new Blob([pdfBlob], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const fileLink = document.createElement('a')
      fileLink.href = url
      fileLink.setAttribute('download', url)
      document.body.appendChild(fileLink)
      fileLink.click()
    }
    const saveAndSend = async () => {
      sending.value = true
      const res = await authApi.sowPdf(+route.params.projectId, false, true)
      if (res) {
        alert('SOW has been sent to the client')
        sending.value = false
        router.push({ name: 'ProjectDocuments', params: { id: projectId } })
      }
    }
    return { savePdf, downloadFile, saveAndSend, sending, saving }
  }
})
</script>

<style lang="sass" scoped>
@import '~@/sass/pdfWrapper.sass'
</style>
