<template>
  <div class="pdf-wrapper">
    <h2>{{ heading }}</h2>
    <div class="head-icons">
      <p>
        <img src="@/assets/icons/Projects.svg" />
        {{ projectDetails.name }}
      </p>
      <p>
        <img src="@/assets/icons/Clients.svg" />
        {{ projectDetails.client.name }}
      </p>
      <p>
        <img src="@/assets/icons/Placeholder.svg" />
        {{ projectDetails.purchaseOrder }}
      </p>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'

export default defineComponent({
  async setup() {
    const route = useRoute()
    const projectId = +route.params.projectId
    const projectDetails = await authApi.getProjectDetails(+projectId)
    const heading = computed(() => {
      if (route.name === 'InvoiceDetail') {
        return 'Invoice preview'
      } else if (route.name === 'SowPdf') {
        return 'Statement of work preview'
      }
      return ''
    })
    return { projectDetails, heading }
  }
})
</script>

<style lang="sass" scoped>
@import '~@/sass/pdfWrapper.sass'
.pdf-wrapper
  padding: 1rem
</style>
