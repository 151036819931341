
import { computed, defineComponent } from 'vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'

export default defineComponent({
  async setup() {
    const route = useRoute()
    const projectId = +route.params.projectId
    const projectDetails = await authApi.getProjectDetails(+projectId)
    const heading = computed(() => {
      if (route.name === 'InvoiceDetail') {
        return 'Invoice preview'
      } else if (route.name === 'SowPdf') {
        return 'Statement of work preview'
      }
      return ''
    })
    return { projectDetails, heading }
  }
})
